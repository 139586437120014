@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Luckiest+Guy&family=Oswald:wght@500&family=Londrina+Shadow&family=Londrina+Solid&display=swap");
/* font-family: 'Lato', sans-serif;
font-family: 'Luckiest Guy', cursive; */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: linear-gradient(0deg, #212a5a, #03a9f4); */
  background: radial-gradient(ellipse at bottom, #1B2735 0%, #090A0F 100%);
  background-size: stretch;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
